import { Component } from '@angular/core';

@Component({
  selector: 'app-carrossel',
  templateUrl: './carrossel.component.html',
  styleUrls: ['./carrossel.component.css']
})
export class CarrosselComponent {
  images = [
    { src: './assets/wof.png', alt: 'Slide 1' },
    { src: './assets/to.jpg', alt: 'Slide 2' },
    { src: './assets/ne.jpg', alt: 'Slide 3' },
    { src: './assets/na.jpg', alt: 'Slide 4' },
    { src: './assets/te.jpg', alt: 'Slide 4' }
  ];
}
