<!-- Page Content -->
<header class="bg-toys py-5 mb-5">
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-lg-2">
        <img src="/assets/logo.jpg" class="imagem-estilizada" />
      </div>
      <div class="col-lg-10" style="padding-left: 80px;">
        <h1 class="display-5 text-white mt-5 mb-2">World of Toys</h1>
        <p class="lead mb-5 text-white">Um mundo  imaginário em uma realidade divertida!</p>
      </div>
    </div>
  </div>
</header>

<div class="container main-container">
  <app-carrossel></app-carrossel>
  <!-- <img src="/assets/wof.png" class="responsive-img" /> -->
  <!-- <div class="row text-center">

    <div class="col-lg-3 col-md-6 mb-4">
      <div class="card h-100">
        <img class="card-img-top" src="/assets/crossplat.jpg" height="164px" width="253px" alt="">
        <div class="card-body">
          <h4 class="card-title">Multiplataforma</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Saiba mais</a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 mb-4">
      <div class="card h-100">
        <img class="card-img-top" src="/assets/performance.jpg" height="164px" width="253px" alt="">
        <div class="card-body">
          <h4 class="card-title">Performance</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo magni sapiente, tempore debitis beatae culpa natus architecto.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Saiba mais</a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 mb-4">
      <div class="card h-100">
        <img class="card-img-top" src="/assets/produtividade.jpg" height="164px" width="253px" alt="">
        <div class="card-body">
          <h4 class="card-title">Produtividade</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Saiba mais</a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 mb-4">
      <div class="card h-100">
        <img class="card-img-top" src="/assets/full_dev.jpg" height="164px" width="253px" alt="">
        <div class="card-body">
          <h4 class="card-title">Funcionalidades</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo magni sapiente, tempore debitis beatae culpa natus architecto.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Saiba mais</a>
        </div>
      </div>
    </div>
  </div> -->
</div>
<!-- Fim Content -->
