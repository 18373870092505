<ul [ngSwitch]="usuarioLogado()" class="navbar-nav">
  <li *ngSwitchCase="false" class="nav-item" style="padding-right: 30px;">
    <a class="btn btn-primary" [routerLink]="['/conta/login']">Entrar</a>
  </li>
  <li *ngSwitchCase="false" class="nav-item">
    <a class="btn btn-success" [routerLink]="['/conta/cadastro']">Crie sua conta</a>
  </li>

  <li *ngSwitchCase="true" class="nav-item">
    <a class="nav-link text-dark" id="saudacaoUsuario" href="javascript:void(0)">{{ email }}</a>
  </li>
  <li *ngSwitchCase="true" class="nav-item">
    <a class="nav-link text-dark" href="javascript:void(0)" (click)="logout()">
      Sair <span class="fas fa-sign-out-alt"></span>
    </a>
  </li>
</ul>
