import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { LocalStorageUtils } from '../utils/localstorage';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private localStorage = new LocalStorageUtils();

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (!request.url.endsWith('/login')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.localStorage.obterTokenUsuario()}`
        }
      });
    }

    return next.handle(request);
  }
}
